import React from 'react'
import { legalName } from 'data/config'
import Container from 'components/common/Container'

const Head = () => (
  <header className="site-header">
    <Container type="wrapper" constraints="centered">
      <div className="container">
        <div className="logo">{legalName}</div>
      </div>
    </Container>
  </header>
)

export default Head
