import React from 'react'
import { Layout, SEO } from 'components/common'
import { Link } from 'gatsby'
import Head from 'components/theme/Head'
import { Footer } from 'components/theme'
import { ModalContainer } from 'reoverlay'
import Container from 'components/common/Container'

const FourOhFour = () => (
  <>
    <Layout>
      <SEO title="404: Not found" location="/404" />
      <Head />
      <Container constraints="narrow" className="fourOfour">
        <h1>404</h1>
        <p className="subhead">Something's wrong.</p>
        <p>
          You just hit a page that doesn&#39;t exist. Just <a href="/">go back</a> or let's us now how we can help you
          contacting the Help Center at <a href="mailto:help@denveradmission.du.edu">help@denveradmission.du.edu</a>.
        </p>
      </Container>
    </Layout>
    <ModalContainer />
    <Footer />
  </>
)

export default FourOhFour
